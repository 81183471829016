import { IoCall, IoMail, IoMap } from 'react-icons/io5';
import {
  ScHeader3,
  ScMaxWidthContainer,
  ScMaxWidthContainerWide,
} from './../components/styled-components';

import Image from '../components/image';
import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';
import globals from '../utils/variables';
import styled from 'styled-components';

const ScBlackContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const content = `
<div
class="idg-text-ajax-loading"
data-text-id="1739092"
data-text-language="de"
>
<p class="adsimple-121739092">
  Informationspflicht laut §5 E-Commerce Gesetz, §14
  Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht
  laut §25 Mediengesetz.
</p>
<p class="adsimple-121739092">
  Maria Rath
  <br />
  Kleinsteinbach 64, <br />
  8283 Bad Blumau, <br />
  Österreich
</p>
<p class="adsimple-121739092">
  <strong>Unternehmensgegenstand:</strong> Ferienwohnung
  <br />
</p>
<p class="adsimple-121739092">
  <strong>Tel.:</strong> +43 664 49 50 184
  <br />
  <strong>E-Mail:</strong> 
  <a href="mailto:maria@gaestehaus-rath.at">
    maria@gaestehaus-rath.at
  </a>
</p>

<strong>Datenschutz Verantwortliche:</strong> Maria Rath
<p class="adsimple-121739092">
  Kontaktdaten der für den Datenschutz verantwortliche Stelle:
  <br />
  Maria Rath
  <br />
  Kleinsteinbach 64
  <br />
  8283 Bad Blumau
  <br />
  E-Mail-Adresse: maria@gaestehaus-rath.at
  <br />
  Vertretungsberechtigt: Lukas Rath
  <br />
</p>
<p style="margin-top:15px">
  Quelle: Erstellt mit dem 
  <a
    href="https://www.adsimple.at/impressum-generator/"
    title="Impressum Generator von AdSimple"
  >
    Impressum Generator
  </a> 
  von AdSimple
</p>
<h2
  id="eu-streitschlichtung"
  class="adsimple-121739092 fusion-responsive-typography-calculated"
  data-fontsize="24"
  style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
  data-lineheight="36px"
>
  EU-Streitschlichtung
</h2>
<p>
  Gemäß Verordnung über Online-Streitbeilegung in
  Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die
  Online-Streitbeilegungsplattform (OS-Plattform) informieren.
  <br />
  Verbraucher haben die Möglichkeit, Beschwerden an die Online
  Streitbeilegungsplattform der Europäischen Kommission unter 
  <a
    class="adsimple-121739092"
    href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE"
    target="_blank"
    rel="noopener"
  >
    http://ec.europa.eu/odr?tid=121739092
  </a> 
  zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb
  in unserem Impressum.
</p>
<p>
  Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder
  verpflichtet sind, an Streitbeilegungsverfahren vor einer
  Verbraucherschlichtungsstelle teilzunehmen.
</p>
<h2
  id="haftung-fuer-inhalte-dieser-webseite"
  class="adsimple-121739092 fusion-responsive-typography-calculated"
  data-fontsize="24"
  style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
  data-lineheight="36px"
>
  Haftung für Inhalte dieser Website
</h2>
<p>
  Wir entwickeln die Inhalte dieser Webseite ständig weiter und
  bemühen uns korrekte und aktuelle Informationen bereitzustellen.
  &nbsp;Leider können wir keine Haftung für die Korrektheit aller
  Inhalte auf dieser Website übernehmen, speziell für jene, die
  seitens Dritter bereitgestellt wurden. Als Diensteanbieter sind wir
  nicht verpflichtet, die von ihnen übermittelten oder gespeicherten
  Informationen zu überwachen oder nach Umständen zu forschen, die auf
  eine rechtswidrige Tätigkeit hinweisen.
</p>
<p>
  Unsere Verpflichtungen zur Entfernung von Informationen oder zur
  Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
  aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben
  auch im Falle unserer Nichtverantwortlichkeit davon unberührt.
</p>
<p>
  Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen,
  bitte wir Sie uns umgehend zu kontaktieren, damit wir die
  rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten
  im Impressum.
</p>
<h2
  id="haftung-links-webseite"
  class="adsimple-121739092 fusion-responsive-typography-calculated"
  data-fontsize="24"
  style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
  data-lineheight="36px"
>
  Haftung für Links auf dieser Webseite
</h2>
<p>
  Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt
  wir nicht verantwortlich sind. Haftung für verlinkte Websites
  besteht für uns nicht, da wir keine Kenntnis rechtswidriger
  Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch
  bisher nicht aufgefallen sind und wir Links sofort entfernen würden,
  wenn uns Rechtswidrigkeiten bekannt werden.
</p>
<p>
  Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte
  wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im
  Impressum.
</p>
<h2
  id="urheberrechtshinweis"
  class="adsimple-121739092 fusion-responsive-typography-calculated"
  data-fontsize="24"
  style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
  data-lineheight="36px"
>
  Urheberrechtshinweis
</h2>
<p>
  Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos)
  unterliegen dem Urheberrecht. Bitte fragen Sie uns bevor Sie die
  Inhalte dieser Website verbreiten, vervielfältigen oder verwerten
  wie zum Beispiel auf anderen Websites erneut veröffentlichen. Falls
  notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte
  unserer Seite rechtlich verfolgen.
</p>
<p>
  Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht
  verletzen, bitten wir Sie uns zu kontaktieren.
</p>
</div>


`;

// <h2
//   id="bildernachweis"
//   class="adsimple-121739092 fusion-responsive-typography-calculated"
//   data-fontsize="24"
//   style="--fontSize:24; line-height: 1.5; --minFontSize:24;"
//   data-lineheight="36px"
// >
//   Bildernachweis
// </h2>
// <p>
//   Die Bilder, Fotos und Grafiken auf dieser Webseite sind
//   urheberrechtlich geschützt.
// </p>
// <p>
//   Die Bilderrechte liegen bei den folgenden Fotografen und
//   Unternehmen:
// </p>
// <ul class="adsimple-121739092">
//   <li class="adsimple-121739092">Fotograf Mustermann</li>
// </ul>
const IndexPage = () => (
  <Layout>
    <SEO title="Balance & Effizienz im Alltag" keywords={globals.keywords} />
    <ScBlackContainer>
      <ScMaxWidthContainer>
        <ScHeader3>Impressum</ScHeader3>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </ScMaxWidthContainer>
    </ScBlackContainer>
  </Layout>
);

export default IndexPage;
